// #region Imports

import React, { useContext, useMemo, useRef, useEffect, useState, useTransition } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';

import * as THREE from 'three';
import { useControls } from 'leva';

// import { button, useControls } from 'leva'; //debug

import { PLANET_SIZE } from '../scenes/Galaxy/components/PlanetManager.tsx';
import { PlanetContextType, PlanetProps, vec3_add } from '../data types/mystarpath_types.tsx';

import earthVertexShader from '../shaders/earth/vertex.glsl';
import earthFragmentShader from '../shaders/earth/fragment.glsl';
import xerxesFragmentShader from '../shaders/Xerxes/fragment.glsl';
import atmosphereVertexShader from '../shaders/atmosphere/vertex.glsl';
import atmosphereFragmentShader from '../shaders/atmosphere/fragment.glsl';

// #endregion


export default function Planet(planetAttributes : PlanetProps) { //Todo, replace PlanetProps with the proper PlanetType ID channel!

    // #region Setup

    // #region States

    const [mouseHover, setMouseHover] = useState(false);

    // #endregion

    // #region Refs

    const sunRef = useRef<THREE.Mesh>();
    const planetRef = useRef<THREE.Mesh>();
    const atmosphereRef = useRef();

    // #endregion

    // Planet's Geometry
    const geometry = useMemo(() => new THREE.SphereGeometry(PLANET_SIZE, 64, 64), []);

    // Destructure the planet attributes
    const { planetID, 
            planetPosition, 
            planetDayTexture, 
            planetNightTexture,
            planetCloudTexture,
            planetColor, 
            planetAtmosphereColor,
            isFocused,
            onClick, goBack, onRender } = planetAttributes;

    console.log("Rendering planet with ID: " + planetID);

    // // #endregion

    // #region Leva controls

    //     // useControls('Camera Position', {
    
    //     //     Go_Back: button(() => { 
    //     //     goBack(); // signal to the parent component that we should go back to the galaxy view
    
    //     //     }),  
    //     // });

    const {theta, phi} = useControls('Sun Position', {

        phi: { value: Math.PI * 0.5, 
               min: 0,
               max: Math.PI,
               step: Math.PI/180, },

        theta: { value: 0.5,
                 min: -Math.PI,
                 max: Math.PI,
        },
    });
    
    // #endregion

    // #region Planet's Material    

    // const planetMaterial = useMemo(() => new THREE.ShaderMaterial({

    //     vertexShader: earthVertexShader,
    //     fragmentShader: earthFragmentShader,
    //     transparent: true, 

    //     uniforms: //Send the textures to the fragment shader
    //     { 
    //         uDayTexture: { value: planetDayTexture },
    //         uNightTexture: { value: planetNightTexture },
    //         uSpecularCloudsTexture: { value: planetCloudTexture },
    //         uSunDirection: { value: new THREE.Vector3(0, 0, 1) },
    //         uAtmosphereDayColor: { value: new THREE.Color(planetColor) },
    //         uAtmosphereTwilightColor: { value: new THREE.Color(planetAtmosphereColor) },
    //     },

    //  }), [planetDayTexture, planetNightTexture, planetCloudTexture, planetColor, planetAtmosphereColor]);

    const planetMaterial = useMemo(() => new THREE.ShaderMaterial({

        vertexShader: earthVertexShader,
        fragmentShader: xerxesFragmentShader,
        transparent: true, 
        
        uniforms: //Send the textures to the fragment shader
        { 
            uDayTexture: { value: planetDayTexture },
            r_Color1: { value: new THREE.Color(0x112a3f) }, // Gradient stop 1 for red channel (The "depth" channel)
            r_Color2: { value: new THREE.Color(0x000000) }, // Gradient stop 2 for red channel
            g_Color1: { value: new THREE.Color(0x466381) }, // Gradient stop 1 for green channel (The "ocean" channel)
            g_Color2: { value: new THREE.Color(0x5A7D9E) }, // Gradient stop 2 for green channel
            b_Color1: { value: new THREE.Color(0x97A4CA) }, // Gradient stop 1 for red channel (The "clouds" channel)
            b_Color2: { value: new THREE.Color(0xFFFFFF) }, // Gradient stop 2 for red channel
        },

     }), [planetDayTexture, planetNightTexture, planetCloudTexture, planetColor, planetAtmosphereColor]);

    //#endregion

    // #region Atmosphere

    // const atmosphereMaterial = new THREE.ShaderMaterial({

    //     side: THREE.BackSide,
    //     transparent: true,
    //     vertexShader: atmosphereVertexShader,
    //     fragmentShader: atmosphereFragmentShader,

    //     uniforms: 
    //     {
    //         uSunDirection: { value: new THREE.Vector3(0, 0, 1) },
    //         uAtmosphereDayColor: { value: new THREE.Color(planetColor) },
    //         uAtmosphereTwilightColor: { value: new THREE.Color(planetAtmosphereColor) },
    //         uRenderAtmosphere: {value: isFocused}, // Only render the atmosphere if the planet is focused on
    //     }
    // });

    // // #endregion

    // // #region React Use Scripts

    // useFrame(({ clock }) => {

    //     const elapsedTime = clock.getElapsedTime();
    
    //     if((planetID == planetContext?.zoomedPlanet?.planetID) && planetRef.current)  // Rotate the planet quicker, if w'ere zoomed into it!
    //     {
    //         planetRef.current.rotation.y = elapsedTime * 0.05;
    //     }

    // });

    // //effect to run on the first render
    // useEffect(() => {
        
    //     onRender(); // Notify the parent component that the planet has been rendered

    //     // const timer = setTimeout(() => {
    //     //   setShowContentWindow(true);
    //     // }, 2000); // Adjust the delay time (in milliseconds) as needed

    //     //return () => clearTimeout(timer); // Cleanup the timer on component unmount

    //   }, []);

    // // Effect to change the cursor style when hovering over the planet
    // useEffect(() => {
    //     document.body.style.cursor = mouseHover ? 'pointer' : 'default';
    // }, [mouseHover])

    // #endregion

    // #region Debug

    useEffect(() => {
        //const gui = new GUI();
        const sunSpherical = new THREE.Spherical(1, phi, theta);
        const sunDirection = new THREE.Vector3();

        sunDirection.setFromSpherical(sunSpherical); // Set the Sun's direction

        //planetMaterial.uniforms.uSunDirection.value.copy(sunDirection);
        //atmosphereMaterial.uniforms.uSunDirection.value.copy(sunDirection);

        //debug
        // sunRef.current.position
        //     .copy(sunDirection)
        //     .multiplyScalar(30);

        sunRef.current?.position.set(sunDirection.x, sunDirection.y, sunDirection.z).multiplyScalar(30);

        return () => {
            //gui.destroy();
        };

    }, [planetMaterial, theta, phi]); //atmosphereMaterial, 

    // #endregion

    // #region JSX Return
          
    return (
        <>
            <mesh ref={sunRef} position={new THREE.Vector3().setFromSpherical(new THREE.Spherical(1, phi, theta))}>
                <icosahedronGeometry args={[1, 2]} />
                <meshBasicMaterial color={0xffffff} />
            </mesh>

            <mesh ref={planetRef}
                  position={planetPosition}
                  geometry={geometry}
                  material={planetMaterial} />

        </>
     );

}
// #endregion


/*
            { isFocused && 
                <mesh ref={atmosphereRef} 
                      position={planetPosition} 
                      geometry={geometry}
                      material={atmosphereMaterial} 
                      scale={[1.04, 1.04, 1.04]} />
            } 

            <mesh ref={planetRef}
                  position={planetPosition}
                  geometry={new THREE.SphereGeometry(0.5, 64, 64)}
                  material={planetMaterial}

                onClick={() => {
                    if(planetContext?.zoomedPlanet == null) // Only allow the planet to be clicked if no other planet is zoomed in
                    {
                        setMouseHover(false); // Reset the mouse pointer
                        onClick(planetID);  Notify the parent component when the planet is clicked, with its ID  
                    }}}

                    onPointerOver={() => { if(planetContext?.zoomedPlanet == null) setMouseHover(true); /* Pointer cursor if no planets are currently zoomed in  }}
                    onPointerOut={() => { setMouseHover(false); }} />
    
                { 
                    isFocused && 
                    <mesh ref={atmosphereRef} position={planetPosition} geometry={new THREE.SphereGeometry(0.5, 64, 64)} material={atmosphereMaterial} scale={[1.04, 1.04, 1.04]} />
                }   

                */