// #region Imports

import React, { useContext, useEffect, useRef, useState, useTransition } from "react";

import TextureProvider, { TextureProviderInstance } from "../../../data_providers/TextureProvider";
import { PlanetType, TextureType } from "../../../data types/mystarpath_types";
import Planet from '../../../content/Planet';

// #endregion

// #region Other Exports

export const PLANET_SIZE = 16;

export function getInitialPlanet() : PlanetType {

    return {
        planetID: "0",
        planetPosition: [0, 0, 0],
        planetDayTexture: TextureType.EARTH_BRIGHT,
        planetNightTexture: TextureType.EARTH_DARK, 
        planetCloudTexture: TextureType.EARTH_SPECULAR_CLOUDS,  
        planetColor: '#093044',
        planetAtmosphereColor: '#2445c1',
        isVisible: false
    };
};

// #endregion

export default function PlanetManager ({onAssetsLoaded}) {

    console.log("(PlanetManager) - Rendering!");

    const _TextureProvider = useContext(TextureProviderInstance);
    const [planetsRendering, beginRendering] = useTransition();

    // #region State Variables

    const [userPlanets, setUserPlanets] = useState<PlanetType[]>([
    {
        planetID: "0",
        planetPosition: [0, 0, 0],
        planetDayTexture: TextureType.XERXES,
        planetNightTexture: TextureType.EARTH_DARK,
        planetCloudTexture: TextureType.EARTH_SPECULAR_CLOUDS,  
        planetColor: '#093044',
        planetAtmosphereColor: '#4a64c7',
        isVisible: false
    }]);
    
    const [assetsLoaded, setAssetsLoaded] = useState(false);

    // #endregion

    // #region Effects

    // Effect for when the PlanetManager is first mounted
    useEffect(() => {

        // Load the textures
        _TextureProvider.loadTextures([TextureType.EARTH_BRIGHT, TextureType.EARTH_DARK, TextureType.EARTH_SPECULAR_CLOUDS, TextureType.XERXES]).then(() => {

            console.log("(PlanetManager) - Textures have been loaded!");
            
            // Mark the next render of the PlanetManager as low-priotity on the UI 
            beginRendering(() => {

                console.log("(PlanetManager) - Rendering planets!");
                setAssetsLoaded(true)
            });

        });

    }, []);

    // Effect for when the assetsLoaded state changes
    useEffect(() => {

        if (assetsLoaded) {

            setTimeout(() => { // Manual delay, for now.
                console.log("(PlanetManager) - All initial assets have been loaded!");
                onAssetsLoaded();
            }, 1000);
        }

    }, [assetsLoaded]);

    // #endregion

    return (

        <>
            { assetsLoaded &&     
                <>    

                    <Planet planetID={userPlanets[0].planetID}
                            planetPosition={userPlanets[0].planetPosition} 
                            planetDayTexture={_TextureProvider.getTexture(userPlanets[0].planetDayTexture)} 
                            planetNightTexture={_TextureProvider.getTexture(userPlanets[0].planetNightTexture)}
                            planetCloudTexture={_TextureProvider.getTexture(userPlanets[0].planetCloudTexture)}
                            planetColor={userPlanets[0].planetColor}
                            planetAtmosphereColor={userPlanets[0].planetAtmosphereColor}
                            isFocused={true}
                            onClick={() => {}}
                            goBack={() => {}}
                            onRender={() => {}} />

                </>
            }
            
        </>
    )
}


/*
                    <Planet planetID={userPlanets[0].planetID}
                            planetPosition={userPlanets[0].planetPosition} 
                            planetDayTexture={_TextureProvider.getTexture(userPlanets[0].planetDayTexture)} 
                            planetNightTexture={_TextureProvider.getTexture(userPlanets[0].planetNightTexture)}
                            planetCloudTexture={_TextureProvider.getTexture(userPlanets[0].planetCloudTexture)}
                            planetColor={userPlanets[0].planetColor}
                            planetAtmosphereColor={userPlanets[0].planetAtmosphereColor}
                            isFocused={true}
                            onClick={() => {}}
                            goBack={() => {}}
                            onRender={() => {}} />

    useEffect(() => {

        // log the total time, in ms
        console.time("Planet Render Time");

        if (hasRendered && currentIndex < planetArray.length) {
        startTransition(() => {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        });
    }
        */