// #region Imports

import * as THREE from 'three';

import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef, createContext, useContext } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { easings, useSpring } from '@react-spring/web';

import { NavigationContext } from '../data_providers/NavigationHelper_Galaxy';
import { AnimationType_GalaxyScene, vec3 } from '../../../data types/mystarpath_types';

// #endregion

// #region Types and Interfaces

// Interface for outside callers, to change the camera's target position
export interface CameraControllerRef { 

}

export type CameraContextType =  {

    near: number; // Near clipping plane
    far: number; // Far clipping plane
    fov: number; // Field of view
    position: vec3; // Position of the camera
    lookAt: vec3; // Where the camera is looking at
    aspectRatio: number; // Aspect ratio
}

type CameraControllerProps = { // Props for the CameraController_Galaxy component

    children: React.ReactNode; // The children of the component
    onAnimationEnd: (AnimationType_GalaxyScene) => void; // Callback function to notify when the animation has ended
};

// #endregion

// #region Other Exports

export const CameraContext = createContext<CameraContextType>(null); // Context for the CameraController_Galaxy component

export const CameraProperties =  {
    fov: 60,
    baseScreenWidth: 1920,
    near: 0.1,
    far: 1000,
};

// #endregion

const CameraController_Galaxy = React.memo(forwardRef<CameraControllerRef, CameraControllerProps>(({children, onAnimationEnd}, ref) => 
{
    console.log("(CameraController_Galaxy) - Rendering!");

    // #region Data Providers

    const {scene, size, set} = useThree(); 
    const navigationContext = useContext(NavigationContext); // Context for the NavigationHelper component

    // #endregion

    // #region State Variables

    const [cameraReady, setCameraReady] = useState(false); // State variable to determine if the camera is ready to be used

    // #endregion

    // #region Refs

    const cameraRef = useRef(new THREE.PerspectiveCamera());
    const currentAnimation = useRef(AnimationType_GalaxyScene.NONE); // Reference to the current animation that is playing

    const cameraPosition = useRef({
        position: navigationContext.initialParameters.initialStartingPosition,
        lookAtPosition: navigationContext.initialParameters.initialCameraLookPosition,
    });

    const previousLookAtPosition = useRef<vec3>(null); // Reference to the previous lookAt position

    // #endregion

    let cameraContext : CameraContextType = { // Value shared to the CameraController_Galaxy's children, via context.
            
            near: CameraProperties.near,
            far: CameraProperties.far,
            fov: CameraProperties.fov,

            position: cameraPosition.current.position, // can be null if the camera hasn't been set yet
            lookAt: previousLookAtPosition.current ?? cameraPosition.current.lookAtPosition, // can be null if the camera hasn't been set yet
            aspectRatio: size.width / size.height,
    };

    // #region Animations

    /* #region Animation config types

        easeInQuad: Accelerates from zero velocity.
        easeOutQuad: Decelerates to zero velocity.
        easeInOutQuad: Accelerates until halfway, then decelerates.
        easeInCubic: Accelerates from zero velocity more aggressively.
        easeOutCubic: Decelerates to zero velocity more aggressively.
        easeInOutCubic: Accelerates until halfway, then decelerates more aggressively.
        easeInQuart: Accelerates from zero velocity even more aggressively.
        easeOutQuart: Decelerates to zero velocity even more aggressively.
        easeInOutQuart: Accelerates until halfway, then decelerates even more aggressively.
        easeInQuint: Accelerates from zero velocity most aggressively.
        easeOutQuint: Decelerates to zero velocity most aggressively.
        easeInOutQuint: Accelerates until halfway, then decelerates most aggressively.
        linear: Constant speed.
*/

    // #endregion

    // #region External Exposure 

//     useImperativeHandle(ref, () => ({

//         // Animation to move the camera slightly in front of a planet
//         //moveToPlanet: (targetPosition: vec3, lookAtPosition?: vec3) => {

//             // console.log("(CameraController_Galaxy) - Triggering the Move Planet Animation to start!"); 

//             // if (camera.position.distanceTo(new THREE.Vector3(...targetPosition)) < 0.1) // If the camera is already at the target position (within a certain tolerance), don't animate it again!
//             // {
//             //     console.log("(CameraController_Galaxy) - Camera is already at the target position...terminating animation.");
//             //     return;
//             // }

//             // const positionToLookAt = lookAtPosition ?? targetPosition; // If no lookAtPosition is provided, look at the center of the new "planet"
//             // setLookAtPosition(positionToLookAt); 

//             // MovePlanetAnimationAPI.start({lookAt: lookAtPosition, // Where do we want to look at?
//             //                               position: [targetPosition[0] + 0.70, targetPosition[1] + 0.50, targetPosition[2]]}); // Where we will be physically moving the camera to); 
//         //},

//  }));

    // #endregion

    // #region Helper Functions

    function adjustAspectRatioCoordinates(animation: AnimationType_GalaxyScene, aspectRatio : number) {

    }

    // #endregion

    // #region React Effects
    
    // Effect that runs on first mount, to add the PerspectiveCamera to the scene
    useEffect(() => {

        console.log("(CameraController_Galaxy) - Setting up the camera!");

        cameraRef.current.aspect = size.width / size.height;
        cameraRef.current.near = CameraProperties.near;
        cameraRef.current.far = CameraProperties.far;
        cameraRef.current.fov = CameraProperties.fov;

        //const enterScenePositions = calculateEnterSceneTranslations(navigationContext.initialParameters.initialStartingPosition, cameraContext.aspectRatio);

        //console.log("(CameraController_Galaxy) - Calculated entrance positions: ", enterScenePositions);

        // Setup the camera's current position, from the NavigationContext
        const initialPosition : vec3 = navigationContext.initialParameters.initialStartingPosition;

        // Set where the camera is initially looking at, from the same context
        const initialLookAtPosition : vec3 = [navigationContext.initialParameters.initialCameraLookPosition[0], 
                                              navigationContext.initialParameters.initialCameraLookPosition[1], 
                                              navigationContext.initialParameters.initialCameraLookPosition[2]];

        // Set the camera's starting positions
        cameraRef.current.position.set(initialPosition[0], initialPosition[1], initialPosition[2]); 
        cameraRef.current.lookAt(initialLookAtPosition[0], initialLookAtPosition[1], initialLookAtPosition[2]); 

        cameraRef.current.updateProjectionMatrix(); // Update the camera's projection matrix, which is necessary for the camera to render correctly

        // Add the PerspectiveCamera to the scene, and set it as the main camera
        scene.add(cameraRef.current);
        set({camera: cameraRef.current});

        // Set the camera as ready to be used!
        setCameraReady(true);        
        
        // Update our camera tracking references
        currentAnimation.current = AnimationType_GalaxyScene.NONE;

        /* ---------------------------------------------------------------------------------- */

        // Cleanup function, for when the component is finally unmounted
        return () => { 
            console.log("(CameraController_Galaxy) - Removing the camera from the scene!");
            scene.remove(cameraRef.current);
        };

    }, []);

    // Effect to adjust the camera's view, based on the size of the window
    useEffect(() => {

        if(cameraRef.current == null)
            return;

        const newAspectRatio = size.width / size.height;

        // Adjust the camera's aspect ratio
        cameraRef.current.aspect = newAspectRatio; 

        console.log("(CameraController_Galaxy) - Adjusting the camera's positioning, based on the aspect ratio");

        // Re-adjust the camera's position and lookAt positions, adjusted based on the aspect ratio   
        adjustAspectRatioCoordinates(currentAnimation.current, newAspectRatio);

    }, [size]);

    // #endregion

    return (
        <>
            <CameraContext.Provider value={cameraContext}>

                { cameraReady && children }

            </CameraContext.Provider>
        </>
    )

}));

export default CameraController_Galaxy;
