import { Texture } from "three";

// #region Animations

export enum AnimationType  {

    NONE,
    ENTER_SCENE_ANIMATION,
    NAVIGATION_WINDOW_CLOSED,
    PRE_BURST,
    BURST,
    SHAKE_SCREEN,
    BOOST,
    FINAL_BURST,
    DECELERATE_SPACESHIP_ANIMATION,
    DECELERATE_HYPERSPACE_ANIMATION,
    EXIT_HYPERSPACE_ANIMATION,
    HUD_TEXT_ENDED,

};

export enum AnimationType_GalaxyScene {
    
    NONE,

}

export interface AnimationProps {

    INTRO_SCREEN_ANIMATION_BEGIN?: {
        startingLookPos: vec3;
    }

    INTRO_SCREEN_ANIMATION_ENDED?: {
        lookAtPos: vec3;
    };

    BURST_ENDED?: {
        setRenderPlanets: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

export interface PulseAnimationProps {

    blur: string,
    brightness: string,
}

// #endregion

// #region Audio

export enum AudioFile {

    HUD_TEXT_BEEP = '/assets/sounds/Interface_Typing.mp3',
    HYPERSPACE_ANIMATION_SOUND = '/assets/sounds/hyperspace-animation-sound.mp3',
    SUBMIT_SOUND = '/assets/sounds/submit-sound.mp3',
}

export type AudioCacheType = {
    
    audioElement: HTMLAudioElement;
    componentsInUse: string[];
    onPlayCallbacks: (() => void)[];
}

export interface AudioControllerContextType {
    
    preloadAudio: (src: AudioFile, caller: string, onPlay?: () => void) => Promise<HTMLAudioElement>;
    playAudio: (src: AudioFile) => void;
}

// #endregion

// #region Data Types and Functions

export class PlanetType {   

    constructor(planet_id : string, position : [number, number, number], dayTexture : TextureType, nightTexture : TextureType,
                color : string, atmosphereColor? : string, cloudTexture?: TextureType, isVisible?: boolean) {

        this.planetID = planet_id;
        this.planetPosition = position;
        this.planetDayTexture = dayTexture;
        this.planetNightTexture = nightTexture;
        this.planetCloudTexture = cloudTexture;
        this.planetColor = color;
        this.planetAtmosphereColor = atmosphereColor ?? color;
        this.isVisible = isVisible ?? false;
    }

    public readonly planetID : string;
    public planetPosition : vec3;
    public planetDayTexture : TextureType;
    public planetNightTexture : TextureType
    public planetCloudTexture : TextureType;
    public planetColor : string;
    public planetAtmosphereColor : string;
    
    public isVisible: boolean;

};

export enum TextureType {

    EARTH_BRIGHT,
    EARTH_DARK,
    EARTH_SPECULAR_CLOUDS,
    LENS_FLARE,
    XERXES,
};

export type vec3 = [number, number, number];
export function vec3_add(a: vec3, b: vec3): vec3
{
    return [
        a[0] + b[0], 
        a[1] + b[1], 
        a[2] + b[2]
    ];
}

// #endregion

// #region Colors

export enum Colors {
    PURPLE_BACKGROUND = '#1e122c',
    DARK_PURPLE_BACKGROUND = '#08030e',
    PURPLE_BASE =  '#491e80',
    NEON_PURPLE =  '#b172fa', 
    FADED_NEON = '#e6cffb',
    SELECTION_YELLOW =  '#eec10d',
    DULL_WHITE = '#e7e7e8',
}

// #endregion

// #region Tailwind Styles

export enum ButtonStyles {
    
    // py-2: padding on the y-axis of 2 units
    // bg-transparent: background color is transparent
    // text-neon-purple: text color is neon purple
    // select-none: text selection is disabled
    // focus:outline-none: outline is removed when the button is focused
    bare = "py-2 bg-transparent text-neon-purple select-none focus:outline-none",
};

// #endregion

// #region Scenes

export enum SceneName {

    NONE,
    HYPERSPACE_SCENE,
    GALAXY_SCENE,
};

export type SceneType = {
    
    sceneName: SceneName;
    page: JSX.Element;
}

export interface SceneInterface {
    sceneAction: (SceneActionType) => void;
}

// #endregion

// #region Website Routes

export enum RouteType {

    HOME_PAGE = "/",
    LOGIN_PAGE = "/login",
    OAUTH_REQUEST_PAGE = "/login/request",

};

// #endregion

// #region Components

export type PlanetContextType = {
    selectedPlanet: PlanetType; //The ID of the planet that is currently selected in the scene
    zoomedPlanet: PlanetType; // The ID of the planet that is currently zoomed in on, in the scene
    scene: SceneInterface; // Add this line to use the renamed interface
};

export type PlanetPlaceholderProps = {
    position: vec3;
    planetColor: string;
}

export type PlanetProps = {

    planetID: string;
    planetPosition: [number, number, number];
    planetDayTexture: Texture; 
    planetNightTexture: Texture;
    planetColor: string;
    planetAtmosphereColor?: string;
    planetCloudTexture?: Texture;
    isFocused?: boolean;
    onRender: () => void; // Event that notifies the parent when the planet has been rendered for the first time
    onClick: (clickedId: string) => void; // Event that notifies the parent component when the planet is clicked
    goBack: () => void; // Event that notifies the parent that we should go back to the galaxy view
};

export type OK_Button = {
    
        buttonStyle: ButtonStyles;
        OnSubmit?: () => void;
        text?: React.ReactNode;
        additionalClassStyling?: string;
};

export type SpaceshipAnimationProps = {
    
    animateShip: boolean; // Whether or not the spaceship should show it's animated thrusters
    shipThrusterLevels: number; // The current level of the spaceship's thrusters (the BloomEffect amount)
    shipThrusterIntensity: number; // The current intensity of the spaceship's thrusters (the BloomEffect intensity)
    shipThrusterPosition_z: number; // The position of the actual thrusters inside of model of the spaceship (we move them forward, while animating).
    targetThrusterColor?: number; // The color of the thrusters
}

// #endregion