import { createContext } from "react";

import { PLANET_SIZE, getInitialPlanet } from "../components/PlanetManager";
import { PlanetType, vec3, vec3_add } from "../../../data types/mystarpath_types";

type initialParametersType = {
    galaxyPosition: vec3,
    initialStartingPosition: vec3,
    initialCameraLookPosition: vec3

    selectedPlanet?: PlanetType,
};

export default class NavigationHelper_Galaxy {

    private static readonly RELATIVE_ZOOM_COORDINATES : vec3 = [-3.6, 10.52, -2.2015]; // The position of the camera at the first zoom level, relative to the selected planet!
    private static readonly DISTANCE_ABOVE_STARTING_PLANET = 0;
    private static readonly DISTANCE_FROM_EDGE_PLANET = 72; // --> 150, on the end of the zoom in animation
    private static readonly LOOKDISTANCE_ABOVE_EDGE_PLANET = 0;

    private initialSelectedPlanet : PlanetType;
    private zoomLocation : vec3; // The position of the camera at the first zoom level in the galaxy view, relative to the selected planet
    private animationPosition : vec3; // The position of the camera right after the hyperspace animation transition ends (where the spaceship "pops out" at)
    private initialStartingPosition : vec3;
    private initialCameraLookPosition : vec3;

    public initialParameters : initialParametersType;

    constructor() {

        this.initialSelectedPlanet = getInitialPlanet();
        
        this.zoomLocation = vec3_add(this.initialSelectedPlanet.planetPosition, NavigationHelper_Galaxy.RELATIVE_ZOOM_COORDINATES);

        this.animationPosition = [this.initialSelectedPlanet.planetPosition[0], 
                                  PLANET_SIZE + NavigationHelper_Galaxy.DISTANCE_ABOVE_STARTING_PLANET, 
                                  NavigationHelper_Galaxy.DISTANCE_FROM_EDGE_PLANET]


        this.initialStartingPosition =  [this.zoomLocation[0], 
                                         NavigationHelper_Galaxy.DISTANCE_ABOVE_STARTING_PLANET, 
                                         this.zoomLocation[2] - NavigationHelper_Galaxy.DISTANCE_FROM_EDGE_PLANET];

        this.initialCameraLookPosition = [this.initialStartingPosition[0], 
                                          this.initialStartingPosition[1], 
                                          this.initialSelectedPlanet.planetPosition[2]]; 

        this.initialParameters = { galaxyPosition: this.zoomLocation,
                                   selectedPlanet: this.initialSelectedPlanet,
                                   initialStartingPosition: this.initialStartingPosition,
                                   initialCameraLookPosition: this.initialCameraLookPosition
                                 };
    }
};

export const NavigationContext = createContext<NavigationHelper_Galaxy>(null); // The context object for the navigation helper
