// #region Imports and Types

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring, useSpringRef } from '@react-spring/web';

import {IoMenuSharp} from 'react-icons/io5';
import {HiSparkles} from 'react-icons/hi2';
import { Colors } from '../data types/mystarpath_types';
import useWindowSize, { WINDOW_MODE } from '../library/WindowInterface';

type NavBarProps = {
  LoginPage_Open: () => void; //signals to the outside world, to open the login page
};

// #endregion

const NavBar = () => {

  console.log("(NavBar) - Rendering!");
  const navigate = useNavigate(); // Hook to navigate to different pages
  const { width, height, mode } = useWindowSize(); // Hook to get the window size
  
  // #region Animations

  const [expandNavBarTrigger, setExpandNavBarTrigger] = useState<boolean>(false);
  const navBarCollapsedHeight = 40; // Height of the NavBar when collapsed
  const expandNavBarTime = 200; // Time in milliseconds for the NavBar to expand

  const getExpandedHeight = (h: number) => {
    if(h < 450) return h * 0.90;
    if(h < 850) return h * 0.65;
    return h * 0.45;
  }

  const navBarHeightAnimation = useSpring({
    height: expandNavBarTrigger ? getExpandedHeight(height) : navBarCollapsedHeight,
    from: { height: navBarCollapsedHeight  }, // Initial collapsed height of the NavVar
    config: { duration: expandNavBarTime },
});

const toggleNavBar = () => { 

  if((mode == WINDOW_MODE.MOBILE) || (mode == WINDOW_MODE.LANDSCAPE)) {
    // Bring into view the SideBar window!

  }
  else // Trigger animation, for Normal and "Halved" window orientations
  {
    setExpandNavBarTrigger(!expandNavBarTrigger)
  }

}

  // #endregion

  // #region Tailwind Styles 

      /* Tailwind CSS breakpoint (width) sizes:
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1280px,
        2xl: 1536px
    */

  const navBarStyle = 'absolute w-full ' +  // Absolutely positioned at the top of the page, taking up the full width of the page
    'flex justify-between ' +  // Flex container, with (default) child items arranged left->right; with space between the items
    'mt-1 xl:mt-2 ' +  // Margin top 2 units on small screens, 4 units on medium screens
    'bg-transparent z-20';  // Ensure that the NavBar can receive click events on top of the Canvas

  const borderContainerStyle = 'flex flex-col w-full sm:w-72 ' + // flex container, column-oriented, and taking up 1/4 of the width of the parent container
    'mx-2 ' + // Margin to the left and right of the container
    'border-2 border-purple-base rounded-lg ' + // Rounded corners, with purple border
    'bg-purple-base bg-opacity-10 ' +  // Background color of the container
    'overflow-hidden'; // Contained items are hidden, unless this container is large enough to display them

  const navItemContainerStyle = 'flex flex-col ' + // Flex container, with child items arranged top->bottom
    'w-full h-full ' + // Taking up the full width and height of the parent container
    'bg-transparent';

  const navBottomContainerStyle = 'flex flex-col ' + // Flex container, with child items arranged top->bottom
    'w-full ' + // Flex container, with child items arranged top->bottom
    'bg-transparent'; // Items are aligned to the end of the container

  const navButtonStyle = 'w-10 h-8 mx-1 ' + // Width and height of the button
    'rounded-lg ' + // Rounded corners
    'cursor-pointer ' + // Cursor changes to pointer when hovering over the button
    'bg-transparent z-20' // Background color of the button

  const navItemStyle = 'w-full h-12 ' + // Take up the full width of parent container
    'mx-1.5 ' + // Margin to the left and right of the item
    'text-left text-dull-white text-xl font-liberation-mono tracking-wide ' + // Button text formatting
    'cursor-pointer ' + // Cursor changes to pointer when hovering over the item
    'bg-transparent ' + // Background color of the item
    'select-none ' + // Text selection is disabled
    'hover:bg-purple-base hover:bg-opacity-20'; // Background color of the item when hovered over

  const createButtonStyle = 'w-full h-12 mx-2 my-2 ' + 
    'bg-purple-base bg-opacity-80 ' + // Background color of the button
    'text-center text-dull-white text-2xl font-bold font-liberation-mono tracking-wide ' + // Button text formatting
    'rounded-md ' + // Rounded corners
    'cursor-pointer select-none'; // Cursor changes to pointer when hovering over the button

  // #endregion

  return (
	<animated.div id='Navbar-top'
                style={navBarHeightAnimation}
                className={navBarStyle}>

        <div id='Border-container'
             className={borderContainerStyle}
             style={{filter: 'brightness(110%)'}}>

              <div id='Nav-Content-container'
                   className='inline-flex'>
                    
                <button id='Nav-button'
                        className={navButtonStyle}
                        onClick={toggleNavBar}>
                          <IoMenuSharp size={36} color={Colors.NEON_PURPLE} />
                </button>

                <div>
                    <h1 className='text-white text-xl'>{width + "|" + height + " | " + mode}</h1>
                </div>
              </div>

              <>
                  <div id='Nav-Items-container'
                       style={{marginTop: '0.25rem'}}
                       className={navItemContainerStyle}>

                  <button id='Nav-Item-Universe'
                          className={navItemStyle}>
                    My Universe
                  </button>

                  <button id='Nav-Item-IdeaNursery'
                          className={navItemStyle}>       
                    My Idea Nursery
                  </button>

                  <button id='Nav-Item-ReaderTimeline'
                          className={navItemStyle}>
                    My Reader's Timeline
                  </button>

                  <button id='Nav-Item-Profile'
                          className={navItemStyle}>
                    My Profile
                  </button>

                  <button id='Nav-Item-Settings'
                          className={navItemStyle}>
                    My Settings 
                  </button>
             
                </div>

                <div id='Nav-Bottom-Container' className={navBottomContainerStyle}>

                  <div className='flex justify-between'>
                      <button id='Nav-Item-LogIn'
                              className={createButtonStyle}
                              onClick={ () => {
                                toggleNavBar(); //hide the NavBar again
                                navigate('/login'); //navigate to the login page
                              }}>
                        Log In
                      </button>

                    <button id='Nav-Item-Create'
                            className={createButtonStyle}>

                      <div className='inline-flex '>
                        Create <HiSparkles className='ml-1 mt-0.5' />
                      </div>
                    </button>

                  </div>

                </div>
              </>          

        </div>
	
	</animated.div>
  );
}

export default React.memo(NavBar);