import React from "react";
import { useRouteError } from "react-router-dom";

type RouteErrorType = {

    statusText: string,
    message: string
}

export default function ErrorScene({ error }: { error?: RouteErrorType }) {

    error = error ?? useRouteError() as RouteErrorType;

    console.error(error); // TODO! Have a better error handling paradigm!

    return (
        <div>
            <h1>Error: {error.statusText}</h1>
            <p>{error.message}</p>
        </div>
    );

}