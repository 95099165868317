// #region Types and Imports

//import { Location, useLocation } from "react-router-dom";
import React, { useEffect, useCallback, useMemo, useImperativeHandle, useState, useRef, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Howler from 'howler';

import './style.css'
import useWindowSize from './library/WindowInterface.ts';
import { SceneType, RouteType, SceneInterface, SceneName } from './data types/mystarpath_types.tsx';
import TextureProvider, { TextureProviderInstance } from './data_providers/TextureProvider.tsx';
import HyperspaceScene from './scenes/Hyperspace/HyperspaceScene.tsx';
import GalaxyScene, { GalaxySceneInterface, GalaxySceneActionItems } from './scenes/Galaxy/GalaxyScene.tsx';
import { useLocation } from 'react-router-dom';

// #endregion

// Disable the auto-unlock feature for Howler.js
Howler.autoUnlock = false; 

const SceneManager = () => 
{
    console.log("(SceneManager) - Now Rendering the SceneManager!");

    // #region State Variables

    const routeLocation = useLocation();
    const navigate = useNavigate(); // Hook to navigate to different pages
    const { loginWithRedirect } = useAuth0();
    const [currentScene, setCurrentScene] = useState<SceneType>({ sceneName: SceneName.NONE, 
                                                                  page: null }); // State variable to determine the current scene
    const { width, height } = useWindowSize(); // Hook to get the window size
    
    // #endregion

    // #region Memos and References

    const textureProvider = useMemo(() => new TextureProvider(), []); // Used to load and grab textures for the active scene
    const pageRef = useRef<SceneInterface>(null); // Reference to the current page
    const GalaxyPage_ref = useRef<GalaxySceneInterface>(null); // Reference to the Galaxy Scene

    // #endregion

    // #region Helper Methods

    function goHome() 
    {
        switch(currentScene.sceneName) {

            case SceneName.NONE:
                console.log("(SceneManager) - Inserting Home Scene, from a Default State!");

                setCurrentScene({
                    sceneName: SceneName.GALAXY_SCENE,
                    page: <GalaxyScene ref={GalaxyPage_ref} />
                });

                break;

            case SceneName.GALAXY_SCENE:
                
                if(GalaxyPage_ref.current) {
                    GalaxyPage_ref.current.goHome();
                }
                else {
                    console.error("(SceneManager) - Galaxy Scene reference is null!");
                }

                break;

            default:
                console.error("(SceneManager) - Current scene (" + currentScene.sceneName + ") does not support opening the login page directly!");
        }
    }   

    function login()
    {
        switch (currentScene.sceneName) {

            case SceneName.NONE: // This is the case where the user navigates directly to the login page (and not from a button link)

                console.log("(SceneManager) - Inserting Login Scene, from a Default State!");

                setCurrentScene({
                    sceneName: SceneName.GALAXY_SCENE,
                    page: <GalaxyScene ref={GalaxyPage_ref} actions={GalaxySceneActionItems.OPEN_LOGIN_PAGE} />
                });

                break;
                
            case SceneName.GALAXY_SCENE:

                console.log("(SceneManager) - Signaling the Galaxy Scene to open the Login Page!");
                    GalaxyPage_ref.current.openLoginPage();
                break;

            default:
                console.error("(SceneManager) - Current scene (" + currentScene.sceneName + ") does not support opening the login page directly!");
        }
    }

    const handleSocialLogin = async(provider) => {

        try {
            await loginWithRedirect({
                authorizationParams: {
                    connection: provider // The name of the connection to use, e.g. google-oauth2
                }
            });
        }
        catch (error) {
            console.error("(LoginPage) - Error logging in with the provider: " + provider);
            console.error(error);
        }
    };

    // #endregion
        
    // #region React Effects

    //Effect that runs on first mount. Currently being used purely for logging purposes
    useEffect(() => {
        console.log("(SceneManager) - Mounted!")

        return () => {console.log("(SceneManager) - Unmounted!")}
    }, []); 

    // Effect that triggers whenever the browser route changes
    useEffect(() => {

        console.log("(SceneManager) - Route has changed! New path: " + routeLocation.pathname);
        const metadata = routeLocation.state; // Get the metadata from the new route

        switch (routeLocation.pathname) {

            case RouteType.HOME_PAGE:
                
                console.log("(SceneManager) - Switching to the Home Page!");
                goHome(); // Signal the SceneManager to switch to the home page

            break;

            case RouteType.LOGIN_PAGE:

                console.log("(SceneManager) - Signaling to the current scene to open the Login Page!");
                login(); // Signal the SceneManager to switch to the login page

                break;

            case RouteType.OAUTH_REQUEST_PAGE:

                console.log("(SceneManager) - Switching to the OAuth Request Page!");

                if(!metadata || !metadata.provider) {
                    console.error("(SceneManager) - 'Provider' Metadata is missing for the OAuth Request Page!");
                    return;
                }

                handleSocialLogin(metadata.provider); // Log in with the specified provider

                break;

            default:
                console.error("Unexpected error: route not found! " + routeLocation);
            }

    }, [routeLocation]);

    // #endregion

    // #region JSX Return

    return (

    <TextureProviderInstance.Provider value={textureProvider}>

        {currentScene.page}

    </TextureProviderInstance.Provider>
                            
    );

    // #endregion

};

export default SceneManager;
