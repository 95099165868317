import React, {createContext, useRef} from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Location } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { Howler } from 'howler'

import { RouteType } from './data types/mystarpath_types'
import SceneManager from './SceneManager'
import ErrorScene from './scenes/Error_Encountered/ErrorScene'
import './style.css'
import LoginPage from './LoginPage'

// Disable autoUnlock to prevent audio from automatically unlocking playing on mobile devices
Howler.autoUnlock = false;

const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;

const redirect_uri = process.env.NODE_ENV === 'development' ? 
                    "https://localhost:5173/login/callback/" : 
                    "https://mystarpath.net/login/callback/";

console.log("Redirect URL: ", redirect_uri);

const root = ReactDOM.createRoot(document.querySelector('#root'));

// redirect_uri = window.location.origin is used to redirect the user back to the original page after authentication
root.render(   

    <Auth0Provider domain={domain}
                   clientId={clientId}
                   authorizationParams={{redirect_uri: redirect_uri}}>

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SceneManager /> }>
                    <Route path="login" element={<></>}>
                        <Route path="request" element={<></>} /> {/* Request route for Auth0 */}
                        <Route path="callback" element={<div>This is the callback page!!</div>} /> {/* Callback route for Auth0 */}
                    </Route>
                </Route>

                <Route path="*" element={<ErrorScene error={{ statusText: "404", message: "Path not found!" }} />} />

            </Routes>
        </BrowserRouter>

    </Auth0Provider>

)