// #region Imports, Types, and Interfaces

import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";

export interface LoadingTextRef {
    startAnimation: () => void;
}

// #endregion

const LoadingText = forwardRef<LoadingTextRef>(({} , ref) => {

    // #region Variables

    const [loadingText, setLoadingText] = useState(""); // The text to display

    const targetText = "LOADING..."; // The target text to animate to
    const textStyle = {
        width: `${targetText.length}ch`, // Set width based on character length
        whiteSpace: "pre" // Preserve whitespace 
    }

    const loadingDotAnimationDuration = 500; // ms

    // #endregion

    // #region Animation 

    // setInterval function, to change to "text" every 500 ms
    useEffect(() => {

        if(loadingText.length == 0) // Don't do anything if the parent component hasn't fully loaded
            return;

        const interval = setInterval(() => {
            
            setLoadingText(prevText => {

                if (prevText.endsWith("...")) {
                    return "LOADING";
                } 
                else {
                    return prevText + ".";
                }

            });

        }, loadingDotAnimationDuration);

        return () => clearInterval(interval); // Cleanup interval on component unmount

    }, [loadingText]); // triggers on first component render

    // #endregion

    // #region Calling methods for the outside worlds

    useImperativeHandle(ref, () => ({

        startAnimation: () => {

            console.log("(LoadingText) - Starting animation!");
            setLoadingText("LOADING"); // Start the animation by giving it an initial value
        }

    }));

    // #endregion

    return (

        <div className="absolute bottom-0 right-0 mx-4 text-5xl text-neon-purple font-liberation-mono font-bold"
             style={textStyle}>

            {loadingText}

        </div>
    );
});

export default LoadingText;
