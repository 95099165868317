import {Texture, SRGBColorSpace, TextureLoader } from "three";
import { TextureType } from "../data types/mystarpath_types";
import { createContext } from "react";

// A map of each TextureType, to their file path location
const TextureMap: Map<TextureType, string> = new Map([

    [TextureType.EARTH_BRIGHT, './assets/earth/day.webp'],
    [TextureType.EARTH_DARK, './assets/earth/night.jpg'],
    [TextureType.EARTH_SPECULAR_CLOUDS, './assets/earth/specularClouds.webp'],
    [TextureType.XERXES, './assets/planets/xerxes/xerxes_colormap2.jpg'],
    [TextureType.LENS_FLARE, './assets/lenses/lensflare.png'],
]);

export default class TextureProvider {

    // #region Properties

    private Textures : Map<TextureType, Texture>;
    private TextureLoader : TextureLoader;

    // #endregion

    // #region Constructor

    constructor() {

        this.Textures = new Map();
        this.TextureLoader = new TextureLoader();
    }

    // #endregion

    // #region Class Methods

    /**
     * Loads the requested textures asynchronously and stores them in the class's texture map.
     * 
     * @param requestedTextures - An array of texture types to be loaded.
     * @returns A promise that resolves when all requested textures have been loaded.
     * 
     * @remarks
     * - If a texture type is not found in the `TextureMap`, an error is logged and the texture is skipped.
     * - If a texture type has already been loaded, a message is logged and the texture is skipped.
     * - Textures are loaded using the `TextureLoader` and are assumed to be encoded in SRGB color space.
     * - If an error occurs during texture loading, the texture is set to `null` in the texture map.
     * 
     * @example
     * ```typescript
     * const textureProvider = new TextureProvider();
     * await textureProvider.loadTextures([TextureType.EARTH_BRIGHT, TextureType.EARTH_DARK]);
     * ```
     */
    public async loadTextures(requestedTextures : TextureType[]) : Promise<void> {

        console.log("Loading textures!");

        let _localTexture : Texture = null;

        // For each of the requestedTextures that exist in TextureMap, load the texture into our class's tracking map!
        for (const textureType of requestedTextures) {

            if(!TextureMap.has(textureType)) {
                console.error(`TextureProvider does not have a recording of type: ${textureType}`);
                continue;
            }

            if(this.Textures.has(textureType)) {
                console.log(`Texture of type: ${textureType} has already been loaded!`);
                continue;
            }

            try {
                // Load the texture
                _localTexture = await this.TextureLoader.loadAsync(TextureMap.get(textureType));
                _localTexture.colorSpace = SRGBColorSpace; // inform the software that all requested textures are encoded in SRGB Color space
                _localTexture.anisotropy = 8; // Set the anisotropy level of the texture, to improve the quality of the texture at low angles

                this.Textures.set(textureType, _localTexture);
            }
            catch (error) {
                console.error(`Error loading texture: ${error}`);

                this.Textures.set(textureType, null); 
            }
        }
    }

    /**
     * Retrieves the texture of the specified type.
     *
     * @param textureType - The type of texture to retrieve.
     * @returns The texture of the specified type, or `null` if the texture is not found.
     * @throws Will log an error to the console if the texture type is not found.
     */
    public getTexture(textureType : TextureType) : Texture {
            
        if(!this.Textures.has(textureType)) {
                
                console.error(`TextureProvider does not have a texture of type: ${textureType}`);
                return null;
        };

        return this.Textures.get(textureType);
    }

    // #endregion

};

export const TextureProviderInstance = createContext<TextureProvider>(null);
