import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web";
import { IoClose } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { BsTwitterX } from "react-icons/bs";

import useWindowSize, { WINDOW_MODE } from './library/WindowInterface';
import { Colors, RouteType } from "./data types/mystarpath_types";

type LoginPageProps = {
    animationFinished: () => void;
};

/*
 * LoginPage.tsx
 * 
 * Behavior of this component, while NOT in the home page::
 * Regular Window:
 * 
 * Halved Window:
 * 
 * Mobile Window:
 * 
 * Landscaspe Window:
*/
export default function LoginPage({animationFinished} : LoginPageProps) {

    console.log("(LoginPage) - Rendering!");

    // #region React Hooks
    
    const navigate = useNavigate(); // Hook to navigate to different pages
    const { width, height, mode } = useWindowSize(); // Hook to get the window size
    const [showContent, setShowContent] = useState<boolean>(false); // State variable to determine if the content should be displayed
    const [expandLoginPageTrigger, setExpandLoginPageTrigger] = useState<boolean>(false); // Controls the growing/shrinking animation of the Login Page
    const [closeButtonHover, setCloseButtonHover] = useState<boolean>(false); // Controls the hover effect of the close button

    // #endregion

    // #endregion

    // #region Helper Functions

    // #region UI Helpers

    const getPanelPositioning = (_m: WINDOW_MODE) : string => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 'left-1/2 top-1/2 '; //Positions the left edge of the element at 50% of the container's width, and translates the element left by 50% of its own width, effectively centering it horizontally.

            case WINDOW_MODE.MOBILE:
                return 'w-full h-full mt-5 mx-3 ';
        }
    }

    const getExpandedWidth = (_m: WINDOW_MODE, w: number) => { //TODO AARON - Change to a variable, that only recalculates when the window size changes! 

        console.log("consoloe.log: " + w);

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 575; //w * 0.30;
        }
    };

    const getExpandedHeight = (_m: WINDOW_MODE, h: number) => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return h * 0.65;
        }
    };

    // #endregion

    // #region Animations

    const heightAnimationRef = useSpringRef();
    const widthAnimationRef = useSpringRef();
    const expandLoginPanelTime = 200; // Time in milliseconds for the Login Page to expand

    const loginPageWidthAnimation = useSpring({
        ref: widthAnimationRef,
        width: expandLoginPageTrigger ? getExpandedWidth(mode, width) : 0,
        from: { width: 0  }, // Initial collapsed height of the NavBar
        config: { duration: expandLoginPanelTime },

        onRest: () => {

            if(expandLoginPageTrigger) {
                console.log("(LoginPage) - Expansion animation has finished!");
                setShowContent(true);
                animationFinished();
            }   
        }

    });

    const loginPageHeightAnimation = useSpring({
        ref: heightAnimationRef,
        height: expandLoginPageTrigger ? getExpandedHeight(mode, height) : 0,
        from: { height: 0  }, // Initial collapsed height of the NavVar
        config: { duration: expandLoginPanelTime },

        onRest: () => {

            if(!expandLoginPageTrigger) {
                console.log("(LoginPage) - Contraction animation has finished!");
                animationFinished();
            }   
        }
    });

    // Use the useChain hook to chain the animations together. 
    // [0, 0.5] means that the first animation will start at 0 seconds, and the second animation will start at 0.5 seconds.
    useChain(expandLoginPageTrigger ? [heightAnimationRef, widthAnimationRef] : [widthAnimationRef, heightAnimationRef], 
        [0, expandLoginPageTrigger ?  0.4 : 0.25]);

    // #endregion

    // #region React Effects

    useEffect(() => {   

        console.log("(LoginPage) - Expanding the Login Page into view!");
        setExpandLoginPageTrigger(true); // trigger the expanding animation of the Login Page

    }, []); // Effect that renders only on component mount, and unmount

    // #endregion

    // #region Tailwind Styles

    const loginContainerStyle = 'absolute z-50 ' + // Absolutely positioned at the top of the page
        getPanelPositioning(mode) +  // Positioning of the panel
        'transform -translate-x-1/2 ' + // Center the panel horizontally
        'transform -translate-y-1/2 ' + // Center the panel vertically
        'bg-dark-purple-background border-2 rounded-lg border-neon-purple '; // Background color of the panel

    const tooltipStyle = 'absolute bottom-full left-1/2 transform -translate-x-1/2 ' + // Tooltip for the exit button 
     'font-liberation-mono ' + // Font style
     'px-2.5 text-sm text-white bg-gray-700 rounded opacity-0 transition-opacity ' +
     `${closeButtonHover ? 'delay-500 opacity-100' : 'opacity-0'}`; // Immediate close on mouse-exit (state variable); delayed open on mouse-enter 

    const exitButtonStyle = 'absolute top-0 left-0 m-1.5 h-8 w-8 flex items-center justify-center cursor-pointer'; // Absolutely positioned at the top-left of the panel

    const exitButtonHoverStyle = 'absolute inset-0 bg-gray-500 rounded-full flex items-center justify-center ' + // Absolutely positioned at the top-left of the panel
        'opacity-0 transition-opacity duration-300 hover:opacity-20'; // Hover effect for the button

    const loginTitleContainerStyle = 'flex flex-col items-center w-full h-full mt-6 bg-transparent ';

    const socialButtonStyle = 'w-1/2 h-10 items-center justify-center mt-10 ' + // Social button size and positioning
        'rounded-lg bg-white ' + // Rounded corners, border, and text color
        'cursor-pointer select-none transition-colors duration-300 hover:bg-dull-white'; // Hover effect for the socials buttons

    const socialButtonStyle_Transparent = 'w-1/2 h-10 items-center justify-center mt-10 ' + // Social button size and positioning
    'text-dull-white rounded-lg border border-gray-400 bg-transparent ' + // Rounded corners, border, and text color
    'cursor-pointer select-none transition-colors duration-300 hover:bg-gray-400 hover:bg-opacity-10'; // Hover effect for the socials buttons

    const textIntputStyle = 'h-12 w-1/2 px-4 py-2 ' + // Text input field for the username/email
        'text-gray-400 bg-transparent border border-gray-400 rounded-sm outline-none ' +
        'font-liberation-mono ' + // Font styl
        'focus:border-neon-purple focus:ring-1 focus:ring-neon-purple'; 


    // #endregion

    //#region JSX Return

    return (

        <animated.div id='Login-page-container'
                      className={loginContainerStyle}
                      style={{...loginPageWidthAnimation,
                              ...loginPageHeightAnimation}}>

            { showContent && <>
            
                <div id="Login-exit-group" className="relative group">

                    <button id="Exit-Login-Button"
                            className={exitButtonStyle}
                            onMouseEnter={() => setCloseButtonHover(true)}
                            onMouseLeave={() => setCloseButtonHover(false)}
                            onClick={() => {
                                setShowContent(false); // Hide the content of the Login Page
                                setExpandLoginPageTrigger(false);
                            }}>
                        
                        <IoClose color={Colors.DULL_WHITE} size={24} /> {/* 'X' icon '*/ }

                        <div className={exitButtonHoverStyle} /> {/* Rounded background, for exit button */ }

                        <div className={tooltipStyle}> {/* Tooltip, for exit button */ }
                            Close
                        </div>

                    </button>

                </div>

                <div className={loginTitleContainerStyle}>
                    <h1 className="text-4xl font-nidus-regular tracking-wider text-dull-white pointer-events-none select-none">Sign in</h1>

                    <button className={socialButtonStyle}
                            onClick={() => navigate(RouteType.OAUTH_REQUEST_PAGE, {state: {provider: "twitter"} }) }> { /* X Sign-in Button */ }

                        <div className="flex w-full h-full items-center justify-center">
                            <BsTwitterX className="mr-1.5" size={20}/> Sign in with X.com
                        </div>

                    </button>

                    <button className={socialButtonStyle} 
                            style={{marginTop: '1.25em'}}
                            onClick={ () => navigate(RouteType.OAUTH_REQUEST_PAGE, {state: {provider: "google-oauth2"} }) }> {/* Google Sign-in Button */}

                        <div className="flex w-full h-full items-center justify-center">
                            <FcGoogle className="mr-1.5" size={20}/> Sign in with Google
                        </div>

                    </button>

                    <div className="flex w-1/2 items-center my-4"> {/* 'or' Divider Line */ }

                        <div className="flex-grow border-t border-gray-400"></div>                  
                            <span className="mx-3 text-dull-white font-bold font-liberation-mono">OR</span>
                        <div className="flex-grow border-t border-gray-400"></div>
                        
                    </div>

                    <input type="text" 
                           placeholder="Email or Username"
                           className={textIntputStyle} /> {/* Manually enter Email or username input box */}

                    <button className={socialButtonStyle} style={{marginTop: '1.25em'}}> {/* Manual sign in "Next" Button */}

                    <div className="flex w-full h-full font-bold items-center justify-center">
                         Next
                    </div>

                    </button>

                    <button className={socialButtonStyle_Transparent} style={{marginTop: '0.75rem'}}> {/* Forgot password? Button */}

                        <div className="flex w-full h-full items-center justify-center">
                            Forgot password?
                        </div>

                    </button>

                    <div className="w-1/2 mt-6 text-right text-gray-400">
                        Don't have an account? <a href="#" className="font-bold font-liberation-mono text-neon-purple">[Sign up]</a>
                    </div>

                </div>

            </>}

        </animated.div>

    );

    // #endregion

}

/*
        <InterfaceButton id="Login_Button"
                         buttonStyle={ButtonStyles.bare} 
                         text={"Login"}
                         onClick={loginWithRedirect} />
                         */