// Planet textures
uniform sampler2D uDayTexture;

uniform vec3 r_Color1, r_Color2; // The "depth" accent colors, for Xerxes.
uniform vec3 g_Color1, g_Color2; // The "ocean" accent colors, for Xerxes.
uniform vec3 b_Color1, b_Color2; // The "cloud" accent colors, for Xerxes.

// uniform vec3 uSunDirection;
// uniform vec3 uAtmosphereDayColor;
// uniform vec3 uAtmosphereTwilightColor;

 varying vec2 vUv;
 varying vec3 vNormal;
 varying vec3 vPosition;

void main()
{
    //vec3 viewDirection = normalize(vPosition - cameraPosition);
    vec3 normal = normalize(vNormal);
    vec3 packedColor = texture(uDayTexture, vUv).rgb;

    // Extract each channel
    float redChannel = packedColor.r;
    float greenChannel = packedColor.g;
    float cloudChannel = packedColor.b;

    vec3 white_color = vec3(1.0);
    vec3 black_color = vec3(0.0);

    // Cloud Channel

    vec3 blue_step = mix(black_color, b_Color1, smoothstep(0.0, 0.618, cloudChannel));
    vec3 white_step = mix(black_color, b_Color2, smoothstep(0.618, 1.0, cloudChannel));
    vec3 cloud_step = white_step + blue_step;

    // Ocean Channel

    vec3 ocean_channel;

    float channelDataLow = 0.0;
    float greenChannelDataHigh = 0.25;
    float gradientChannelStart = 0.259;
    float gradientChannelEnd = 1.0;
    float gradientStop1 = 0.350;
    float gradientStop2 = 0.618;
    float gradientStop3 = 0.718;

    // Remap greenChannel from [0.0, 0.2] to [0.259, 1.0]
    // Equation: remapped_x = c + (x - a) * ((d - c) / (b - a))
    // [a,b] = [0.0, 0.2], 
    // [c,d] = [0.259, 1.0]
    // [a,b] = where most of the data in the compressed green channel lies
    // [c,d] = gradient data, from Blender texture image
    float remappedGreen = gradientChannelStart + (greenChannel) * ((gradientChannelEnd - gradientChannelStart) / (greenChannelDataHigh));

    // Now apply the remapped green channel to the gradient
    if (remappedGreen <= gradientChannelStart) {
        ocean_channel = black_color; 
    }
    else if (remappedGreen <= gradientStop1) {
        ocean_channel = mix(black_color, g_Color1, smoothstep(gradientChannelStart, gradientStop1, remappedGreen));
    }
    else if (remappedGreen <= gradientStop2) {
        ocean_channel = mix(g_Color1, g_Color2, smoothstep(gradientStop1, gradientStop2, remappedGreen));
    }
    else if (remappedGreen <= gradientStop3) {
        ocean_channel = mix(g_Color2, black_color, smoothstep(gradientStop2, gradientStop3, remappedGreen));
    }
    else {
        ocean_channel = black_color;
    }

    // Depth Channel

    // Gradient "stop" position = 0.350 (r_Color2, black)
    // Gradient "start" position = 0.150 (r_Color1)
    // Anything less than 0.150 is "on" (r_Color1)

    // From manual testing, it appears that most of the "on" data is compressed into the lower 0.00475
    vec3 depth_channel;
    vec3 test_channel;

    float redChannelDataHigh = 0.150;
    float redGradientChannelStart = 0.35;
    float redGradientChannelEnd = 0.650;

    float remappedRedChannel = redGradientChannelStart + (redChannel) * ((gradientChannelEnd - redGradientChannelStart) / (redChannelDataHigh));
    test_channel = mix(black_color, vec3(1.0, 0.0, 0.0), smoothstep(0.633, 1.0, remappedRedChannel));

    // if(remappedRedChannel < redGradientChannelStart) {
    //     depth_channel = black_color;
    // }
    // else if (remappedRedChannel <= redGradientChannelEnd) {
    //     depth_channel = vec3(0.0, 0.0, 1.0);
    // }
    // else {
    //     depth_channel = vec3(1.0,0.0,0.0);
    // }

    if(remappedRedChannel < redGradientChannelStart)
    {
        depth_channel = r_Color2;
    }
    else if(remappedRedChannel < redGradientChannelEnd)
    {
        depth_channel = mix(r_Color2, r_Color1, smoothstep(redGradientChannelStart, redGradientChannelEnd, remappedRedChannel));
    }
    else
    {
        depth_channel = r_Color1;
    }

    vec3 final_color = ocean_channel + cloud_step + depth_channel;

    gl_FragColor = vec4(final_color, 1.0);
    #include <tonemapping_fragment>
    #include <colorspace_fragment>

// #region code to add back in 

    // // Sun orientation
    // float sunOrientation = dot(uSunDirection, normal);

    // // Day / night color
    // float dayMix = smoothstep(- 0.25, 0.5, sunOrientation);
    // vec3 dayColor = texture(uDayTexture, vUv).rgb;
    // vec3 nightColor = texture(uNightTexture, vUv).rgb;
    // color = mix(nightColor, dayColor, dayMix);

    // // Specular cloud color
    // vec2 specularCloudsColor = texture(uSpecularCloudsTexture, vUv).rg;

    // // Clouds
    // float cloudsMix = smoothstep(0.075, 1.0, specularCloudsColor.g);
    // cloudsMix *= dayMix;
    // color = mix(color, vec3(1.0), cloudsMix);

    // // Fresnel
    // float fresnel = dot(viewDirection, normal) + 1.0;
    // fresnel = pow(fresnel, 2.0);

    // // // Atmosphere
    // float atmosphereDayMix = smoothstep(- 0.5, 1.0, sunOrientation);
    // vec3 atmosphereColor = mix(uAtmosphereTwilightColor, uAtmosphereDayColor, atmosphereDayMix);
    // color = mix(color, atmosphereColor, fresnel * atmosphereDayMix);

    // // Sun's Specular
    // vec3 reflection = reflect(- uSunDirection, normal);
    // // if the reflection and view direction are aligned, the specular is at its maximum
    // // otherwise, we want the specular to decrease
    // float specular = - dot(reflection, viewDirection);
    // specular = max(specular, 0.0); // clamp the specular's values
    // specular = pow(specular, 32.0); // shrink the specular

    // vec3 specularColor = mix(vec3(1.0), atmosphereColor, fresnel); // mix the specular color with the atmosphere's color, but only on the edges of the planet

    // specular *= specularCloudsColor.r; // apply the specular, so that only the water reflects the sun
    // color += specular * specularColor; 

    // // Final color
    // gl_FragColor = vec4(color, 1.0);
    // #include <tonemapping_fragment>
    // #include <colorspace_fragment>

    // #endregion
}