import { get } from 'http';
import { useState, useEffect } from 'react';

export enum WINDOW_MODE {
   
  NORMAL = 0, // Aspect Ratios: 1.3 -> 2.6
  HALVED = 1, // Aspect Ratios: 0.6 -> 1.3
  MOBILE = 2, // Aspect Ratios: < 0.6
  LANDSCAPE = 3 // Aspect Ratios: > 2.6
};

function getWindowMode(width: number, height: number): WINDOW_MODE
{
  const aspectRatio = width / height;

  if(aspectRatio < 0.72) return WINDOW_MODE.MOBILE;
  if(aspectRatio > 2.1) return WINDOW_MODE.LANDSCAPE;
  if(aspectRatio < 1.4) return WINDOW_MODE.HALVED;

  return WINDOW_MODE.NORMAL; // default case
}

export default function useWindowSize() 
{
  // Function state variables  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    mode: getWindowMode(window.innerWidth, window.innerHeight)
  });

  useEffect(() => {

    const handleResize = () => {

      // Update the internal configuration from the window
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        mode: getWindowMode(window.innerWidth, window.innerHeight)
      }); 
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
