// #region Imports, Types, and Interfaces

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { animated, easings, useSpring } from "@react-spring/web";

import LoadingText, {LoadingTextRef} from "../../../library/LoadingText";

type LoadingScreenProps = {

    loadingScreenVisible: boolean;
    onFadeOutFinished: () => void;
};

export interface LoadingScreenRef {

    // Function to start the "fade-out" opacity animation of the interface
    hideInterface: () => void;
};

// #endregion

const IntroductionLoadingScreen = forwardRef<LoadingScreenRef, LoadingScreenProps>(({loadingScreenVisible, onFadeOutFinished}, ref) => {

    console.log("(IntroductionLoadingScreen) - Rendering!");

    // #region Variables

    const [loadingTextVisible, setLoadingTextVisible] = useState<boolean>(true); // State variable to determine if the loading text is visible or not
    const loadingTextRef = useRef<LoadingTextRef>(null);

    // #endregion

    // #region Animations

    const fadeAnimationDuration = 1500; // in ms

    // Animation to fade the opacity of the loading screen out
    const [fadeAnimation, fadeAnimationAPI] = useSpring(() => ({ 

        from: { opacity: 1 },

        to: { }, // This will be set and triggered by the animation's caller

        config: { duration: fadeAnimationDuration,
                  easings: easings.linear,
                  precision: 0.0001
        },

        onStart: ({value}) => {
            console.log("(IntroductionLoadingScreen) - Starting fadeAnimation!");
        },

        onRest: ({value}) => {
            console.log("(IntroductionLoadingScreen) - Finished fadeAnimation!");

            // Call the onFadeOutFinished callback
            onFadeOutFinished();
        }
    }));

    // #endregion
        
    // #region Effects

    // Effect to start the loading text's animation, after a short delay once this component is mounted for the first time
    useEffect(() => {

        setTimeout(() => {
            loadingTextRef.current?.startAnimation();
        }, 500);

        return () => {
            console.log("(IntroductionLoadingScreen) - Unmounting!");        
        } 

    }, []);

    // Effect to control the fade-out animation of the loading screen
    useEffect(() => {

        if (!loadingScreenVisible) {
            
            setLoadingTextVisible(false); // Hide the loading animation

            fadeAnimationAPI.start({ to: { opacity: 0 } }); // Start fading out the loading screen
        }

    }, [loadingScreenVisible]);

    // #endregion

    // #region JSX Return

        return (
            <>
                <animated.div className="absolute top-0 w-full h-full bg-[#0e0f0f] z-20 pointer-events-none"
                              style={fadeAnimation}>

                    { loadingTextVisible && 
                        <LoadingText ref={loadingTextRef} />
                    }

                </animated.div>
            </>
        );

    // #endregion
    
});

export default IntroductionLoadingScreen;